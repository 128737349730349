import AboutUs from "../../components/about/AboutUs"

function AboutPage() {
  return (
    <div>
      <AboutUs />
    </div>
  )
}

export default AboutPage;