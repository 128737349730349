function Services() {
  return (
    <div className="py-5 lg:px-[13%] xl:px-[15%] xxs:px-[5%] sm:px-[10%] space-y-10">
      <h2 className="text-[rgba(4,60,170,1)] text-[36px] font-[400]  ">
        Our Services:
      </h2>
      <div className="flex justify-between py-5 px-3 border-2 border-[rgba(4,60,170,0.5)] rounded-lg hover:shadow-md hover:shadow-[rgba(4,60,170,0.8)] cursor-pointer xxs:flex-col-reverse lg:flex-row">
        <div className="lg:w-[70%] xxs:w-full">
          <p className="text-[rgba(4,60,170,1)] text-[26px] font-[400] mb-4 leading-7 ">
            1. Pharmaceutical Process Optimization:
          </p>
          <p className="text-xl leading-7">
            We understand the significance of efficient manufacturing processes
            in the pharmaceutical industry. Our experts work closely with your
            team to identify bottlenecks, streamline workflows, and implement
            cutting-edge technologies that foster continuous improvement.
            Through our process optimization strategies, we help you enhance
            productivity, reduce time-to-market, and gain a competitive edge.
          </p>
        </div>
        <img src="/alpharmalogo.png" alt="alpharma" className="my-auto mx-auto" />
      </div>

      <div className="flex justify-between py-5 px-3 border-2 border-[rgba(4,60,170,0.5)] rounded-lg hover:shadow-md hover:shadow-[rgba(4,60,170,0.8)] cursor-pointer xxs:flex-col lg:flex-row">
        <img src="/alpharmalogo.png" alt="alpharma" className="my-auto mx-auto" />
        <div className="lg:w-[70%] xxs:w-full">
          <p className="text-[rgba(4,60,170,1)] text-[26px] font-[400] mb-4 leading-7 ">
            2. Healthcare Supply Chain Management:
          </p>
          <p className="text-xl leading-7">
            Efficient supply chain management is vital for successful healthcare
            organizations. Our specialized consultancy services focus on
            optimizing your healthcare supply chain, reducing costs, and
            ensuring timely access to critical medical supplies. From supply
            chain analysis to vendor optimization, we leverage our expertise to
            enhance your supply chain's efficiency.
          </p>
        </div>
      </div>

      <div className="flex justify-between py-5 px-3 border-2 border-[rgba(4,60,170,0.5)] rounded-lg hover:shadow-md hover:shadow-[rgba(4,60,170,0.8)] cursor-pointer xxs:flex-col-reverse lg:flex-row">
        <div className="lg:w-[70%] xxs:w-full">
          <p className="text-[rgba(4,60,170,1)] text-[26px] font-[400] mb-4 leading-7 ">
            3. Regulatory Compliance and Quality Assurance:
          </p>
          <p className="text-xl leading-7">
            Navigating the complex world of regulatory requirements is crucial
            to ensure product safety and maintain compliance. Our team of
            regulatory experts provides tailored solutions to meet global
            industry standards, safeguarding your reputation and mitigating
            risks. We guide you through the intricate regulatory landscape,
            empowering your organization to excel.
          </p>
        </div>
        <img src="/alpharmalogo.png" alt="alpharma" className="my-auto mx-auto" />
      </div>

      <div className="flex justify-between py-5 px-3 border-2 border-[rgba(4,60,170,0.5)] rounded-lg hover:shadow-md hover:shadow-[rgba(4,60,170,0.8)] cursor-pointer xxs:flex-col lg:flex-row">
        <img src="/alpharmalogo.png" alt="alpharma" className="my-auto mx-auto" />
        <div className="lg:w-[70%] xxs:w-full">
          <p className="text-[rgba(4,60,170,1)] text-[26px] font-[400] mb-4 leading-7 ">
            4. Data Analytics and Business Intelligence:
          </p>
          <p className="text-xl leading-7">
            Harness the power of data to make informed decisions and gain a
            competitive edge. Our cutting-edge data analytics and business
            intelligence services enable you to derive actionable insights from
            your data. With our data-driven approach, you can identify market
            trends, optimize marketing strategies, and make strategic decisions
            with confidence.
          </p>
        </div>
      </div>

      <div className="flex justify-between py-5 px-3 border-2 border-[rgba(4,60,170,0.5)] rounded-lg hover:shadow-md hover:shadow-[rgba(4,60,170,0.8)] cursor-pointer xxs:flex-col-reverse lg:flex-row">
        <div className="lg:w-[70%] xxs:w-full">
          <p className="text-[rgba(4,60,170,1)] text-[26px] font-[400] mb-4 leading-7 ">
            5. Medical Affairs Consulting:
          </p>
          <p className="text-xl leading-7">
            Establishing trust and credibility within the medical community is
            vital. Our expert medical affairs consultancy assists you in medical
            communications, scientific engagement, KOL management, and strategic
            planning. With our support, you can enhance your reputation and
            build lasting relationships with key stakeholders.
          </p>
        </div>
        <img src="/alpharmalogo.png" alt="alpharma" className="my-auto mx-auto" />
      </div>

      <div className="flex justify-between py-5 px-3 border-2 border-[rgba(4,60,170,0.5)] rounded-lg hover:shadow-md hover:shadow-[rgba(4,60,170,0.8)] cursor-pointer xxs:flex-col lg:flex-row">
        <img src="/alpharmalogo.png" alt="alpharma" className="my-auto mx-auto" />
        <div className="lg:w-[70%] xxs:w-full">
          <p className="text-[rgba(4,60,170,1)] text-[26px] font-[400] mb-4 leading-7 ">
            6. Pharmaceutical Marketing and Branding:
          </p>
          <p className="text-xl leading-7">
            In a competitive pharmaceutical landscape, effective marketing and
            branding strategies are essential for success. Collaborate with our
            team to develop targeted marketing campaigns, position your brand
            strategically, and devise market access strategies. Our innovative
            approach ensures that you connect with your target audiences,
            creating enduring brand value and capturing market share.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
