const homepageInfo = [
  {
    title: "Optimize Your Success",
    info: "Pharmaceutical Process Excellence - Partner with Alpharma Solutions to identify bottlenecks, streamline workflows, and implement cutting-edge technologies. Elevate productivity, reduce time-to-market, and gain a competitive edge through our process optimization strategies.",
  },
  {
    title: "Streamline Your Operations",
    info: "Healthcare Supply Chain Mastery - Experience efficient healthcare supply chain management with our specialized consultancy. Reduce costs, ensure timely access to critical medical supplies, and enhance your supply chain's efficiency with Alpharma Solutions.",
  },
  {
    title: "Navigate with Confidence",
    info: "Regulatory Compliance and Quality Assurance - Trust our regulatory experts to guide you through global industry standards, safeguarding your reputation and mitigating risks. Excel in quality assurance and regulatory compliance with Alpharma Solutions.",
  },
  {
    title: "Unleash the Power of Data",
    info: "Data Analytics and Business Intelligence - Derive actionable insights, identify market trends, and optimize strategies with our cutting-edge data analytics and business intelligence services. Make informed decisions confidently with Alpharma Solutions.",
  },
  {
    title: "Build Lasting Relationships",
    info: "Medical Affairs Consulting - Establish trust and credibility within the medical community through our expert medical affairs consultancy. From communications to strategic planning, Alpharma Solutions supports you in building lasting relationships with key stakeholders.",
  },
  {
    title: "Create Enduring Brand Value",
    info: "Pharmaceutical Marketing and Branding - Stand out in the competitive pharmaceutical landscape by collaborating with our team. Develop targeted marketing campaigns, strategically position your brand, and capture market share with Alpharma Solutions' innovative approach.",
  },
];

const headerInfo = [{
  title: "Welcome to Alpharma Solutions - ",
  info: "Where Excellence Meets Innovation in Pharmaceuticals!",
}];

const myImage = "/alpharmalogo.png";

export { homepageInfo, headerInfo, myImage };
