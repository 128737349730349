import { headerInfo } from "../../data";

function TopSection() {
  const info = headerInfo.map((info, index) => (
    <div key={index} className="py-10 px-10 text-center">
      <h1 className="text-4xl font-[400] ">{info.title} {info.info}</h1>
    </div>
  ));

  return <section className="flex justify-center items-center h-[91vh] bg-cover bg-center " style={{ backgroundImage: 'url(/alpharmabg.jpg)' }}>{info}</section>;
}

export default TopSection;
