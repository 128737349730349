import { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleLinkClick = () => {
    setShowMenu(false);
  };

  return (
    <nav className="h-[9vh] flex justify-between text-[20px] text-white font-[400] py-3 px-7 bg-[rgba(4,60,170,1)] sticky top-0 z-[1000] ">
      <Link className="my-auto" to="/">
        <img
          src="/alpharma.png"
          alt="Alpharma Solutions"
          className="cursor-pointer my-auto w-[30%] border rounded-lg bg-white "
        />
      </Link>
      <section className="flex md:gap-x-10 xl:gap-x-14 xxs:hidden md:flex ">
        <Link to="/" className="my-auto">
          Home
        </Link>
        <Link to="/about-us" className="my-auto">
          About Us
        </Link>
        <Link to="/services" className="my-auto">
          Services
        </Link>
        {/* <Link to="/blogs" className="my-auto">
          Blogs
        </Link> */}
        <Link
          to="/contact-us"
          className="bg-[rgba(27, 199, 104, 1)] border border-white rounded-lg py-1 px-5  my-auto "
        >
          Contact Us
        </Link>
      </section>
      {!showMenu ? (
        <GiHamburgerMenu
          className="text-white my-auto text-2xl md:hidden xxs:block cursor-pointer"
          onClick={handleMenuClick}
        />
      ) : (
        <RxCross1
          className="text-white my-auto text-2xl md:hidden xxs:block cursor-pointer"
          onClick={handleMenuClick}
        />
      )}
      {showMenu && (
        <section className="xxs:flex flex-col absolute left-0  py-3 px-7 w-full md:hidden top-[9vh] bg-[rgba(4,60,170,1)] border-t border-[rgba(27,199,104,1)] ">
          <Link to="/" className="my-auto" onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/about-us" className="my-auto" onClick={handleLinkClick}>
            About Us
          </Link>
          <Link to="/services" className="my-auto" onClick={handleLinkClick}>
            Services
          </Link>
          {/* <Link to="/blogs" className="my-auto" onClick={handleLinkClick}>
            Blogs
          </Link> */}
          <Link to="/contact-us" className="my-auto" onClick={handleLinkClick}>
            Contact Us
          </Link>
        </section>
      )}
    </nav>
  );
}

export default Header;
