import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/homepage/HomePage";
import AboutPage from "../pages/about/AboutPage";
import ServicesPage from "../pages/services/ServicesPage";
// import BlogsPage from "../pages/blogs/BlogsPage";
import ContactUsPage from "../pages/contact/ContactUsPage";

function Routings() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/about-us" element={<AboutPage />} />
      <Route exact path="/services" element={<ServicesPage />} />
      {/* <Route exact path="/blogs" element={<BlogsPage />} /> */}
      <Route exact path="/contact-us" element={<ContactUsPage />} />
    </Routes>
  );
}

export default Routings;
