import ContactUs from "../../components/contact/ContactUs";

function ContactUsPage() {
  return (
    <div>
      <ContactUs />
    </div>
  )
}

export default ContactUsPage;