import Home from "../../components/home/Home";
import TopSection from "../../components/home/TopSection";

function HomePage() {
  return (
    <div>
      <TopSection />
      <Home />
    </div>
  )
}

export default HomePage;