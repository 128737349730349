function ContactUs() {
  return (
    <div className="py-5 lg:px-[13%] xl:px-[15%] xxs:px-[5%] sm:px-[10%] space-y-3 mb-10 ">
      <h2 className="text-[rgba(4,60,170,1)] text-[36px] font-[400]  ">
        Contact Us:
      </h2>
      <div>
        <p><strong>Phone Number:</strong>  +254781561887</p>
      </div>
    </div>
  );
}

export default ContactUs;
