import Header from "./header/Header";
import Footer from "./footer/Footer";
import Routings from "../routes/Routings";

function App() {
  return (
    <div className="">
      <Header />
      <Routings />
      <Footer />
    </div>
  );
}

export default App;
