import { homepageInfo, myImage } from "../../data";

function Home() {
  const homeContent = homepageInfo.map((info, index) => (
    <div
      key={index}
      className="flex justify-between py-5 px-3 border-2 border-[rgba(4,60,170,0.5)] rounded-lg hover:shadow-md hover:shadow-[rgba(4,60,170,0.8)] cursor-pointer xxs:flex-col lg:flex-row"
    >
      <div className="lg:w-[70%] xxs:w-full">
        <p className="text-[rgba(4,60,170,1)] text-[26px] font-[400] mb-4 leading-7 ">
          {info.title}
        </p>
        <p>{info.info}</p>
      </div>
      <img src={myImage} alt={info.title} className="my-auto mx-auto" />
    </div>
  ));

  return (
    <div className="py-5 lg:px-[13%] xl:px-[15%] xxs:px-[5%] sm:px-[10%] space-y-10">
      <h2 className="text-[rgba(4,60,170,1)] text-[36px] font-[400] text-center  ">
        Key Benefits
      </h2>
      {homeContent}
      <div>
        <h2 className="text-[rgba(4,60,170,1)] text-[36px] font-[400] text-center  ">
          Why choose us:
        </h2>
        <ul className="text-xl space-y-3 leading-7">
          <li>
            <strong>Specialized Expertise:</strong> Our team comprises seasoned
            consultants with extensive experience in the pharmaceutical and
            healthcare industries. We bring specialized expertise to address
            your unique challenges.
          </li>
          <li>
            <strong>Client-Centric Approach:</strong> We believe in forging
            strong partnerships with our clients. Our solutions are tailored to
            your specific needs and objectives, ensuring your success is at the
            forefront of our strategy.
          </li>
          <li>
            <strong>Innovative Solutions:</strong> Embracing innovation is our
            mantra. We stay ahead of industry trends and leverage cutting-edge
            technologies to offer the most effective and transformative
            solutions.
          </li>
          <li>
            <strong>Sustainable Growth:</strong> Our consultancy services are
            designed to drive sustainable growth. We empower you to thrive in a
            dynamic industry while maintaining compliance and adhering to
            ethical standards.
          </li>
          <li>
            <strong>Enduring Partnerships:</strong> At Alpharma Solutions, we
            prioritize building enduring partnerships with our clients. Your
            success is our success, and we are committed to supporting your
            journey towards excellence.
          </li>
        </ul>
      </div>
      <div className="text-xl space-y-2">
        <p className="">
          Choose Alpharma Solutions for a future of pharmaceutical excellence.
          Let's elevate your success together.
        </p>
        <p>Ready to Optimize? Contact Alpharma Solutions Now!</p>
      </div>
    </div>
  );
}

export default Home;
