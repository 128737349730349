import { BsCheckCircleFill } from "react-icons/bs";

function AboutUs() {
  return (
    <div className="py-5 lg:px-[13%] xl:px-[15%] xxs:px-[5%] sm:px-[10%] space-y-3">
      <h2 className="text-[rgba(4,60,170,1)] text-[36px] font-[400]  ">
        About Us:
      </h2>
      <div className="text-xl leading-7 space-y-3">
        <p>
          At Alpharma Solutions, we are on a mission to revolutionize the
          pharmaceutical and healthcare sectors through specialized consultancy
          services. Our team of seasoned experts is committed to empowering
          organizations with tailored solutions in process optimization, supply
          chain management, regulatory compliance, data analytics, medical
          affairs, and marketing strategies. As a trailblazing consultancy firm,
          we strive to be your strategic partner in navigating complex
          challenges and achieving sustainable growth in today's ever-evolving
          market.
        </p>
        <p>
          <strong>We offer several services including:</strong>
        </p>
        <ul className="text-xl  list-none leading-8 xxs:ml-3 sm:ml-6">
          <li className="flex">
            <BsCheckCircleFill className="my-auto text-[rgba(4,60,170,1)] mr-3" />
            Pharmaceutical Process Optimization
          </li>
          <li className="flex">
            <BsCheckCircleFill className="my-auto text-[rgba(4,60,170,1)] mr-3" />
            Healthcare Supply Chain Management
          </li>
          <li className="flex">
            <BsCheckCircleFill className="my-auto text-[rgba(4,60,170,1)] mr-3" />
            Regulatory Compliance and Quality Assurance
          </li>
          <li className="flex">
            <BsCheckCircleFill className="my-auto text-[rgba(4,60,170,1)] mr-3" />
            Data Analytics and Business Intelligence
          </li>
          <li className="flex">
            <BsCheckCircleFill className="my-auto text-[rgba(4,60,170,1)] mr-3" />
            Medical Affairs Consulting
          </li>
          <li className="flex">
            <BsCheckCircleFill className="my-auto text-[rgba(4,60,170,1)] mr-3" />
            Pharmaceutical Marketing and Branding
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AboutUs;
