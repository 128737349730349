import { useState } from "react";
import { BsSend } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaCopyright } from "react-icons/fa";

function Footer() {
  // Email state
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState("");

  // Handle email change
  const handleInput = (event) => {
    setEmailInput(event.target.value);
  };

  // Handle submit email
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValidEmail(emailInput)) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");
    // alert(emailInput);
    setEmailInput("");
  };

  // Email validation
  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  // Current year
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[rgba(4,60,170,1)] lg:px-[13%] xl:px-[15%] py-[40px] text-white xxs:px-[5%] sm:px-[10%] ">
      <section className="flex justify-between mb-16 xxs:flex-col lg:flex-row ">
        <div className="space-y-3">
          <p className="text-3xl font-[400] ">Sign up for our Newsletter</p>
          <p className="text-[18px] font-[400] ">
            Get to know updates in the field of pharmacy
          </p>
        </div>
        <div className="lg:w-[50%] xxs:w-full md:w-[90%] space-y-3">
          <p className="text-2xl">Your Email</p>
          <div className="flex flex-col">
            <div className="flex ">
              <input
                type="email"
                name="email"
                placeholder="example@gmail.com"
                value={emailInput}
                onChange={handleInput}
                className="text-black outline-none rounded-l-[50px] py-2 px-5 xxs:w-[70%] sm:w-[75%] "
              />
              <button
                onClick={handleSubmit}
                className="xxs:w-[30%] sm:w-[25%] bg-[rgba(27,199,104,1)] rounded-r-[50px] px-[4%] flex justify-between items-center text-center   "
              >
                Subscribe <BsSend />
              </button>
            </div>
            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
      </section>
      <section className="flex flex-wrap gap-y-5 xxs:text-center sm:text-start">
        <div className="space-y-2 xxs:w-full sm:w-[50%] md:w-[40%]">
          <p className="text-2xl font-[500]">Alpharma Solutions</p>
          <p>Your favorite online pharmacy consultancy. </p>
        </div>
        <div className="space-y-2 xxs:w-full sm:w-[50%] md:w-[20%]">
          <p className="text-xl font-[400] ">Quick Links</p>
          <ul>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            {/* <li>
              <Link to="/blogs">Blogs</Link>
            </li> */}
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="space-y-2 xxs:w-full sm:w-[50%] md:w-[20%] ">
          <p className="text-xl font-[400] ">Services</p>
          <ul>
            <li>Consultancy</li>
          </ul>
        </div>
        <div className="space-y-2 xxs:w-full sm:w-[50%] md:w-[20%]">
          <p className="text-xl font-[400] ">Social Media</p>
          <ul>
            <Link>Facebook</Link>
            <Link>Twitter</Link>
          </ul>
        </div>
      </section>
      <section className="flex gap-x-2 mt-10">
        <FaCopyright className="my-auto" />
        <p>{currentYear}</p>
      </section>
    </footer>
  );
}

export default Footer;
